.linkWrapper::before,
.linkWrapper::after {
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgb(109, 59, 255);
  background: linear-gradient(
    90deg,
    rgba(109, 59, 255, 1) 0%,
    rgba(6, 148, 158, 1) 28%,
    rgba(34, 201, 251, 1) 59%,
    rgba(160, 250, 255, 1) 100%
  );
  top: 100%;
  left: 0;
  pointer-events: none;
}

.linkWrapper::before {
  content: '';
  /* show by default */
  height: 2px;
  opacity: 0;
}

.linkWrapper:hover span {
  animation: glitchText 0.4s linear;
}

.linkWrapper:hover::before {
  opacity: 1;
  animation: glitchLine 0.4s steps(2, start) forwards;
}

@keyframes glitchLine {
  0% {
    transform: scale3d(1, 1, 1);
  }

  10% {
    transform: translate3d(10px, 0, 0);
  }

  20% {
    transform: translate3d(0, 4px, 0);
  }

  30% {
    transform: scale3d(0.1, 1.4, 1) translate3d(0, -25px, 0);
    transform-origin: 100% 0%;
  }

  40% {
    transform: scale3d(1, 0.3, 1) translate3d(0, 25px, 0);
  }

  50% {
    transform: scale3d(0.5, 0.3, 1) translate3d(-100px, -80px, 0);
  }

  60% {
    transform: scale3d(1, 1.25, 1) translate3d(10px, -5px, 0);
  }

  70% {
    transform: scale3d(0.5, 0.5, 1) translate3d(0, 20px, 0);
  }

  80% {
    transform: translate3d(-30, 10px, 0) scale3d(1, 0.4, 1);
    transform-origin: 100% 0%;
  }

  90% {
    transform: scale3d(1, 0.5, 1) translate3d(0, -15px, 0);
    transform-origin: 0% 50%;
  }

  100% {
    opacity: 1;
  }
}
